.navbar-container {
    width: 80vw;
    margin-left: 50%;
    transform: translate(-50%);
    margin-top: 80px;;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.logo {
    padding: 0;
    border: none;
    background: none;
    width: 70px;
}

.logo img {
    width: 100%;
}

.navbar-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    float: right;
}

.navbar-button {
    font-family: azo-sans-web, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: right;
    font-size: 20px;
    line-height: 22px;
    background: none;
    padding: 0;
    border: none;
    margin-left: 20px;
}