.forms-container {
    width: 600px;
    margin-left: 50%;
    transform: translate(-50%);
    font-family: azo-sans-web, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: right;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    padding: 40px;
    margin-bottom: 140px;
    margin-top: 100px;
}

.contact-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-message {
    text-align: center;
    font-size: 24px;
    line-height: 28px;
}

.input-label {
    margin-top: 20px;
    text-align: center;
    width: calc( 100%);
}

.input {
    border: 0.5px solid #707070;
    font-family: azo-sans-web, sans-serif;
    font-weight: 400;
    font-size: 16px;
    padding: 10px 20px;
    font-style: normal;
    text-align: left;
    width: calc( 100% - 40px);
    border-radius: 5px;
    margin-bottom: 20px;
}

.form-button {
    width: 120px;
    height: 40px;
    font-family: azo-sans-web, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    background-color: #FFB100;
    border: none;
    color: white;
    border-radius: 5px;
    font-size: 20px;
}

.form-button-again {
    margin-top: 20px;
    width: 160px;
    height: 40px;
    font-family: azo-sans-web, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    background-color: #FFB100;
    border: none;
    color: white;
    border-radius: 5px;
    font-size: 20px;
    padding: 6px 29.4px;
    margin-left: 50%;
    transform: translate(-50%)
}

@media only screen and (max-width: 1000px) {
    .forms-container {
        width: calc(90vw - 80px);
        margin-bottom: 80px;
        margin-top: 60px;
    }
}