.contact-container {
    width: 80vw;
    margin-left: 50%;
    transform: translate(-50%);
    margin-bottom: 80px;
    ;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contact-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    float: right;
}

.contact-info {
    font-family: azo-sans-web, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: right;
    font-size: 16px;
    line-height: 22px;
    background: none;
    padding: 0;
    border: none;
    margin-left: 20px;
}

.contact-social {
    margin-left: 20px;
    background: none;
    padding: 0;
    border: none;
    width: 30px;
}

.contact-social img {
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .contact-container {
        margin-top: 50px;
        flex-direction: column;
    }
    .contact-items {
        flex-direction: column;
    }
    .logo {
        margin-bottom: 10px;
        text-align: center;
    }
    .contact-info {
        margin-top: 10px;
        margin-left: 0px;
    }
    .contact-social {
        margin-left: 0px;
        margin-top: 10px;
    }
}