html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
  /* cursor: none; */
  /* cursor: url('./images/cursor.png')  52.5 52.5, auto; */
}

code {}

.parallax-outer {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}

button:focus {
  outline-style: none;
}

button {
  cursor: pointer;
}

.desktop {
  display: inherit;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: inherit;
  }
}